<template>
  <div>
    <Modal :options="{width: '80vw', close: true}" @close="cerrar_modal">
      <div class="title">Detalles - Solicitud {{ solicitud_sel.solicitud_id }}</div>
      <div class="body">
        <div class="row linea_titulo">
          <div class="col-sm-6">Información de solicitud</div>
          <div class="col-sm-2"><button class="btn warning-btn" @click="obtener_solicitud">Recargar</button></div>
          <div class="col-sm-2"><button class="btn secondary-btn" @click="ver_observaciones=true">Observaciones</button></div>
          <div v-if="solicitud.estatus == 1" class="col-sm-2"><button class="btn danger-btn" @click="cancelar_solicitud=true" :disabled="!$auth.can('metricas','solicitudes_cancelar')">Cancelar solicitud</button></div>
          <div v-else class="col-sm-2"><button class="btn warning-btn" @click="solicitud_reactivar" :disabled="!$auth.can('metricas','solicitudes_reanudar')">Reactivar solicitud</button></div>
        </div>
        <div class="row mb-3">
          <div :class="solicitud.estatus == 1 ? 'col-sm-6' : 'col-sm-4'">
            <ul>
              <li>Solicitud: {{ solicitud.solicitud_id }}</li>
              <li>Estatus: <span :class="'badge '+(solicitud.estatus == 1 ? 'badge-success' : 'badge-danger')">{{ solicitud.estatus == 1 ? 'Activa' : 'Cancelada' }}</span></li>
            </ul>
          </div>
          <div :class="solicitud.estatus == 1 ? 'col-sm-6' : 'col-sm-4'">
            <ul>
              <li>Total de líneas temporales: {{ Object.keys(lineas_temporales).length }}</li>
              <li>Total de SLA: {{ solicitud.sla.length }}</li>
              <li>Total de SLA canceladas: {{ solicitudes_canceladas }}</li>
            </ul>
          </div>
          <div v-if="solicitud.estatus == 0">
            Cancelación:
            <ul>
              <li>Usuario: {{ solicitud.usuario_cancelacion }}</li>
              <li>Razón: {{ solicitud.razon_cancelacion }}</li>
              <li>Fecha: {{ solicitud.cancel_at }}</li>
            </ul>
          </div>
        </div>

        <div class="row">
          <template v-for="(slas,index) in lineas_temporales">
            <div class="col-sm-12 linea_titulo">{{ index }}</div>
            
            <div v-for="sla in slas" :key="sla.id" class="col-sm-3 mb-4">
              <div :class="'card'+tipo_tarjeta(index, sla.id)">
                <div class="card-body">
                  <h5 class="card-title">{{ sla.estatus != 0 ? sla.etapa.etapa : sla.etapa.etapa+' CANCELADA' }}</h5>
                  <h6 class="card-subtitle mb-2">{{ sla.etapa.aplicacion }}</h6>
                  <p class="card-text">
                    <ul>
                      <li>Usuario: {{ sla.usuario }}</li>
                      <li>Ingreso: {{ $moment(sla.created_at).locale('es').format(formato_fechas) }}</li>
                      <li v-if="sla.etapa.etapa_final == 0">Tiempo máximo de vída: {{ tiempo_maximo_vida(sla.etapa.tiempo_max) }}</li>
                      <li v-if="sla.etapa.etapa_final == 0">Tiempo en etapa: {{ sla.tiempo_vida_humano }}</li>
                      <li>Estatus: {{ solicitud_estatus(sla.estatus) }}</li>
                    </ul>
                    <template v-if="sla.estatus == 0">
                    <p>Cancelación</p>
                    <ul>
                      <li>Usuario: {{ sla.usuario_retrocedio }}</li>
                      <li>Razón: {{ sla.razon_retrocedio }}</li>
                      <li>Tiempo: {{ $moment(sla.cancel_at).locale('es').format(formato_fechas) }}</li>
                    </ul>
                    </template>
                  </p>
                </div>
              </div>
            </div>
          </template>

        </div>

      </div>
    </Modal>

    <Cancelar v-if="cancelar_solicitud" :solicitud="solicitud_sel" @update="solicitud_cancelada" @close="cancelar_solicitud=false"/>

    <Observaciones v-if="ver_observaciones" :solicitud="solicitud" @close="cerrar_observaciones" @update="obtener_solicitud" />
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'
  import Cancelar from './Cancelar'
  import Observaciones from './Observaciones'

  import api from '@/apps/metricas/api/solicitudes'

  export default {
    components: {
      Modal, Cancelar, Observaciones
    }
    ,props: {
      solicitud_sel: null
    }
    ,data: function() {
      return {
        solicitud: {
          estatus: 1,
          sla:[]
        }
        ,formato_fechas: 'ddd DD MMM YYYY HH:mm:ss A'
        ,cancelar_solicitud: false
        ,ver_observaciones: false
      }
    }
    ,created: function() {
      this.$moment.locale('es');
    }
    ,mounted: function() {
      this.obtener_solicitud();
      // this.obtener_cancelacion();
    }
    ,methods: {
      obtener_solicitud: async function() {
        try {
          this.solicitud = (await api.obtener_solicitud(this.solicitud_sel.id)).data;

          // this.$log.info('lineas_temporales: ',this.slas);
        }catch(e) {
          this.$log.info('err', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,obtener_cancelacion: async function() {
        try {
          this.cancelacion = (await api.obtener_cancelacion(this.solicitud_sel.id)).data;
        }catch(e) {
          this.$log.info('err', e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cerrar_modal: function() {
        this.$emit('close');
      }
      ,calcular_tiempo_etapa: function(linea_temporal, orden) {
        let fecha_inicio = null;
        let fecha_inicio_sig_etapa = null;

        let slas = this.lineas_temporales[linea_temporal];

        let siguiente = false;
        for(let i=0; i<slas.length; i++) {
          if (siguiente) {
            fecha_inicio_sig_etapa = slas[i].created_at;
            siguiente = false;
          }

          if (slas[i].orden == orden) {
            fecha_inicio = slas[i].created_at;
            siguiente = true;
          }
        }

        if (!fecha_inicio_sig_etapa)
          return 'En etapa actual';

        let timestamp_inicio = this.$moment(fecha_inicio).unix();
        let timestamp_siguiente = this.$moment(fecha_inicio_sig_etapa).unix();
        let segundos = timestamp_siguiente - timestamp_inicio;
        // this.$log.info('segundos',segundos);

        let mensajes = '';

        if (segundos > 86400) {
          let dias = Math.floor(segundos / 86400);
          segundos = segundos - (dias * 86400);
          mensajes = dias+ ' día(s)';
        }

        // this.$log.info('segundos pos dias',segundos);

        if (segundos > 3600) {
          let horas = Math.floor(segundos / 3600);
          segundos = segundos - (horas * 3600);
          if (mensajes == '') {
            mensajes = horas+' hora(s)';
          }else {
            mensajes += ' y '+horas+' hora(s)';
          }
        }

        // this.$log.info('segundos pos horas',segundos);

        if (segundos > 60) {
          let minutos = Math.floor(segundos / 60);
          segundos = segundos - (minutos * 60);
          if (mensajes == ''){
            mensajes = minutos+' minuto(s)';
          }else {
            mensajes += ', con '+minutos+' minuto(s)';
          }
        }

        if (segundos > 0) {
          if (mensajes == '')
            mensajes = segundos+' segundo(s)';
          else
            mensajes += ' y '+segundos+' segundo(s)';
        }

        // this.$log.info('segundos pos minutos',segundos);

        return mensajes;
      }
      ,tipo_tarjeta: function(linea_temporal, id) {
        let siguiente = false;
        let existe_siguiente = false;
        let sla_actual = null;
        let sla_siguiente = null;
        let ultima_activa = 0;

        let sla = this.lineas_temporales[linea_temporal];

        for(let i=0; i<sla.length; i++) {
          if (siguiente) {
            existe_siguiente = true;
            sla_siguiente = sla[i];
            siguiente = false;
          }

          if(sla[i].id == id){
            sla_actual = sla[i];
            siguiente = true;
          }

          if (sla[i].estatus == 1)
            ultima_activa = sla[i].id
        }

        this.$log.info('=============================================');
        this.$log.info('sla_actual.estatus', sla_actual.estatus);
        if (sla_actual.estatus == 0)
          return ' card_cancelada';

        if (!existe_siguiente)
          return ' card_actual';

        if (ultima_activa == sla_actual.id)
          return ' card_actual';

        this.$log.info('sla_actual', sla_actual.id)
        let etapa_vida_maxima = sla_actual.etapa.tiempo_max;
        let creacion_actual = sla_actual.tiempo_vida_segundos;
        this.$log.info('creacion_actual.tiempo_vida_segundos',creacion_actual)

        let porcentaje = Math.ceil((creacion_actual / etapa_vida_maxima) * 100) ;
        this.$log.info('porcentaje',porcentaje);
        
        if (porcentaje > 100)
          return ' card_vieja_danger';

        if (porcentaje > 70 && porcentaje <= 100)
          return ' card_vieja_warning';

        return ' card_vieja_ok';
      }
      ,resuelta_en: function(linea_temporal, id) {
        let siguiente = false;
        let existe_siguiente = false;
        let sla_actual = null;
        let sla_siguiente = null;
        let ultima_activa = 0;

        let sla = this.lineas_temporales[linea_temporal];

        for(let i=0; i<sla.length; i++) {
          if (siguiente) {
            existe_siguiente = true;
            sla_siguiente = sla[i];
            siguiente = false;
          }

          if(sla[i].id == id){
            sla_actual = sla[i];
            siguiente = true;
          }

          if (sla[i].estatus == 1)
            ultima_activa = sla[i].id
        }

        if (sla_actual.estatus == 2 && !sla_siguiente)
          return 'No resuleta';

        if (!existe_siguiente && sla_actual.estatus == 1)
          return 'Etapa activa';

        if (ultima_activa == sla_actual.id && sla_actual.estatus == 1)
          return 'Etapa activa';

        // this.$log.info('sla', sla_actual)
        let etapa_vida_maxima = sla_actual.etapa.tiempo_max;
        let creacion_actual = this.$moment(sla_actual.created_at).unix();
        let creacion_siguiente = this.$moment(sla_siguiente.created_at).unix();

        let tiempo_vida = creacion_siguiente - creacion_actual;
        let porcentaje = Math.ceil((tiempo_vida / etapa_vida_maxima) * 100) ;
        // this.$log.info('porcentaje',porcentaje);
        
        return porcentaje+'% del tiempo de vida máxima';
      }
      ,tiempo_maximo_vida: function(tiempo) {
        let mensaje = '';

        // this.$log.info('tiempo',tiempo);

        if (tiempo > 86400) {
          let dias = Math.floor(tiempo / 86400);
          tiempo = tiempo - (dias * 86400);
          mensaje += dias+' día(s)';
        }

        if (tiempo > 3600) {
          let horas = Math.floor(tiempo / 3600);
          tiempo = tiempo - (horas * 3600);
          if (mensaje == '')
            mensaje = horas+' hora(s)';
          else
            mensaje += ' y '+horas+' hora(s)';
        }

        if (tiempo > 60) {
          let minutos = Math.floor(tiempo / 60);
          tiempo = tiempo - (minutos * 60);
          if (mensaje == '')
            mensaje = minutos+' minutos(s)';
          else
            mensaje += ', '+minutos+' minutos(s)';
        }

        if (tiempo > 0) {
          if (mensaje == '')
            mensaje = tiempo+' segundos(s)';
          else
            mensaje += ', '+tiempo+' segundos(s)';
        }

        return mensaje;
      }
      ,solicitud_cancelada: function(solicitud) {
        this.$log.info('cancelar',solicitud);
        this.solicitud = solicitud;

        this.$helper.showMessage('Exito','La solicitud '+this.solicitud.solicitud_id+' se a cancelado correctamente','success');
      }
      ,solicitud_reactivar: async function() {
        try {
          this.solicitud = (await api.reactivar_solicitud({
            solicitud_id: this.solicitud.solicitud_id,
            usuario: this.$auth.getUser().nombre,
            aplicacion: 'Constelacion',
            identificador: 'solicitud_detalles'
          })).data;

          this.$helper.showMessage('Exito','La solicitud '+this.solicitud.solicitud_id+' se a reactivado correctamente','success');
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cerrar_observaciones: function() {
        this.ver_observaciones = false;
      }
      ,solicitud_estatus: function(estatus) {
        switch(estatus) {
          case 1:
            return 'Activa';
            break;
          case 2:
            return 'Cerrada';
            break;
          case 0:
            return 'Cancelada';
        }
      }
    }
    ,computed: {
      estatus: function() {
        switch(this.solicitud.estatus) {
          case 0:
            return 'Cancelado';
            break;
          case 1:
            return 'Activo';
            break;
          case 2:
            return 'Inactivo';
            break;
        }
      }
      ,lineas_temporales: function() {
        let lineas_temporales = {};

        for(let i=0; i<this.solicitud.sla.length; i++) {
          let linea_temporal = 'Línea temporal: '+this.solicitud.sla[i].linea_temporal;
          // this.$log.info('linea_temporal', linea_temporal);

          if (!lineas_temporales[linea_temporal])
            lineas_temporales[linea_temporal] = [];

          lineas_temporales[linea_temporal].push(this.solicitud.sla[i]);
        }

        return lineas_temporales;
      }
      ,lineas_temporales_limpias: function() {
        let lineas_temporales = {};

        for(let i=0; i<this.solicitud.sla.length; i++) {
          let linea_temporal = 'Línea temporal: '+this.solicitud.sla[i].linea_temporal;
          // this.$log.info('linea_temporal', linea_temporal);

          if (!lineas_temporales[linea_temporal])
            lineas_temporales[linea_temporal] = [];

          if(this.solicitud.sla[i].estatus == 1)
            lineas_temporales[linea_temporal].push(this.solicitud.sla[i]);
        }

        return lineas_temporales;
      }
      ,solicitudes_canceladas: function() {
        let canceladas = 0;

        this.solicitud.sla.forEach(sla => {
          if (sla.estatus == 2)
            canceladas++;
        })

        return canceladas;
      }
    }
  }
</script>

<style lang="scss">
  .linea_titulo {
    background-color: #4C4C4C;
    color: #fff;
    font-size: 1.3em;
    padding-top: 10px;
    padding-bottom: 10px;
    margin-bottom: 20px;
  }

  .card {
    min-height: 325px;
  }

  .card_actual {
    border: solid 1px #95ABFF;

    .card-body {
      .card-title {
        background-color: #D1DAFD;
        padding: 5px;
      }
    }
  }

  .card_vieja_ok {
    .card-body {
      .card-title {
        background-color: #BADBBA;
        padding: 5px;
      }

      .card-title, .card-text {
        color: #6AB76A !important;
      }

      .card-subtitle {
        color: #A3D3A3;
      }
    }
  }

  .card_vieja_warning {
    .card-body {
      border:solid 1px #DEDC6F;
      border-radius: 0.25rem;

      .card-title {
        background-color: #DEDC6F;
        padding: 5px;
      }

      .card-title, .card-text {
        color: #94910E !important;
      }

      .card-subtitle {
        color: #CDCC99;
      }
    }
  }

  .card_vieja_danger {
    .card-body {
      border: solid 1px #DDBFBF;
      border-radius: 0.25rem;
      .card-title {
        background-color: #DDBFBF;
        padding: 5px;
      }

      .card-title, .card-text {
        color: #C38F8F !important;
      }

      .card-subtitle {
        color: #D8BCBC;
      }
    }
  }

  .card_cancelada {
    .card-body {
      border: solid 1px #CCCCCC;
      border-radius: 0.25rem;
      .card-title {
        background-color: #E6E6E6;
        padding: 5px;
      }

      .card-title, .card-text {
        color: #808080 !important;
      }

      .card-subtitle {
        color: #BFBFBF;
      }
    }
  }

  .badge-success, .badge-danger {
    color: #fff !important;
  }
</style>