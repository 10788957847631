<template>
  <div>
    <Modal :options="{width: '40vw'}" @close="cerrar_modal">
      <div class="title">Cancelar solicitud</div>
      <div class="body">
        <div class="row form-group">
          <label for="payload.solicitud_id" class="col-form-label col-sm-2">Solicitud</label>
          <div class="col-sm-10"><input v-model="payload.solicitud_id" type="text" name="payload.solicitud_id" id="payload.solicitud_id" class="form-control" readonly></div>
        </div>
        <div class="row form-group">
          <label for="payload.razon" class="col-form-label col-sm-2">Razón de cancelación</label>
          <div class="col-sm-10">
            <textarea v-model="payload.razon" name="payload.razon" id="payload.razon" cols="30" rows="10" class="form-control"></textarea>
          </div>
        </div>
      </div>
      <div class="footer">
        <div class="row">
          <div class="col-sm-3 offset-sm-6"><button class="btn secondary-btn" @click="cancelar_solicitud">Cancelar solicitud</button></div>
          <div class="col-sm-3"><button class="btn danger-btn" @click="cerrar_modal">Cerrar modal</button></div>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script type="text/javascript">
  import Modal from '@/components/Modal'

  import api from '@/apps/metricas/api/solicitudes'

  export default {
    components: {
      Modal
    }
    ,props: {
      solicitud: {
        type: Object
      }
    }
    ,data: function() {
      return {
        payload: {
          solicitud_id: 0
          ,usuario: null
          ,razon: null
          ,aplicacion: 'Constelacion'
          ,identificador: 'solicitud_detalles'
        }
      }
    }
    ,mounted: function() {
      this.payload.solicitud_id = this.solicitud.solicitud_id;
      this.payload.usuario = this.$auth.getUser().nombre;
    }
    ,methods: {
      cancelar_solicitud: async function() {
        try {
          if (!this.payload.razon || this.payload.razon == '' || this.payload.razon.length < 5)
            return this.$helper.showMessage('Error','Debes indicar la razón a cancelar mayor a 5 caracteres','error','alert');

          let res = (await api.cancelar_solicitud(this.payload)).data;

          this.$emit('update',res);

          this.cerrar_modal();
        }catch(e) {
          this.$log.info('error',e);
          this.$helper.showAxiosError(e,'Error');
        }
      }
      ,cerrar_modal: function() {
        this.$emit('close');
      }
    }
  }
</script>